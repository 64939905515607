import { registerLocaleData } from '@angular/common';
import { loadTranslations } from '@angular/localize';
import { supportedLanguages, SupportedLanguages } from '@blockframes/model';
import { fetchTranslationFile } from './utils';

import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';

/**
 * @dev run this command to generate the translation files:
 * npx nx run waterfall:extract-i18n --format=json --output-path=./apps/waterfall/waterfall/src/assets
 * 
 * @dev created from https://stackblitz.com/~/github.com/TheSlimvReal/angular-runtime-translations
 * Ressources:
 * https://angular.io/api/localize/loadTranslations#description
 * https://angular.io/cli/extract-i18n
 * @param locale 
 * @param config
 * @returns 
 */

export async function initLanguage(locale: SupportedLanguages, config: { loadTranslations: boolean, useRemoteI18nFiles?: boolean }): Promise<void> {
  if (!Object.keys(supportedLanguages).includes(locale)) locale = 'en';

  if (config.loadTranslations) {
    if (config.useRemoteI18nFiles) {
      try {
        const { json } = await fetchTranslationFile(locale);
        loadTranslations(json.translations);
      } catch (_) {
        console.log('Failed to load remote translation files, falling back to local files');
        const { json } = await fetchTranslationFile(locale, 'local');
        loadTranslations(json.translations);
      }
    } else {
      const { json } = await fetchTranslationFile(locale, 'local');
      loadTranslations(json.translations);
    }
  }

  $localize.locale = locale;

  // Load required locale module
  switch (locale) {
    case 'fr': {
      /**
       * @dev If needed, dynamic import can also be used : 
       * const localeModule = await import('../../../../node_modules/@angular/common/locales/fr');
       * registerLocaleData(localeModule.default);
       */
      registerLocaleData(localeFr);
      break;
    }
    case 'es': {
      registerLocaleData(localeEs);
      break;
    }
    case 'en': {
      // Default locale is already loaded
      break;
    }
    default:
      console.warn('No locale module loaded');
      break;
  }

}
