export type FilmRow = {
  registrationNumber: string;
  registrationDate: string;
  title: string;
  register: string;
  category: string;
  format: string;
  genre: string;
  durationType: string;
  url?: string;
};

export type ContractRow = {
  orderNumber: string;
  registrationNumber: string;
  registrationDate: string;
  mainDisposition: string;
  assignor: string;
  beneficiary: string;
  cancelled: string;
  associatedRegistration: string;
  url?: string;
};

interface BaseSearchResult {
  type: 'single' | 'multiple' | 'none';
  url?: string;
  registrationNumber?: string;
  registrationDate?: string;
  message?: string;
}

export interface FilmSearchResult extends BaseSearchResult {
  results?: FilmRow[];
}

export interface ContractSearchResult extends BaseSearchResult {
  results?: ContractRow[];
}
