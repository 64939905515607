import { MessageId, TargetMessage } from '@angular/localize/localize';
import { supportedLanguages, SupportedLanguages, SupportedLocaleIds, UploadData } from '@blockframes/model';
import { firebase } from '@env';

export type TranslationFileTypes = 'raw' | SupportedLanguages;
export const translationFileTypes = ['raw', ...Object.keys(supportedLanguages)] as TranslationFileTypes[];
type TranslationData = Record<MessageId, TargetMessage>;
export type TranslationJson = { locale: SupportedLocaleIds, translations: TranslationData };
type TranslationEntries = Partial<Record<TranslationFileTypes, TargetMessage>>;
export type TranslationRow = { id: MessageId, entries: TranslationEntries, build: TranslationEntries };
export const i18nStoragePath = 'i18n';
export const i18nArchivePath = 'archive';
export const i18nLatestBuildPath = 'latest-build';

const fileTypeLocale: Record<TranslationFileTypes, SupportedLocaleIds> = {
  raw: 'en-US',
  en: 'en-US',
  fr: 'fr-FR',
  es: 'es-ES',
}

interface TranslationFile {
  lang: TranslationFileTypes;
  json: TranslationJson;
}

export async function fetchTranslationFile(lang: TranslationFileTypes, mode: 'remote' | 'local' | 'latest-build' = 'remote'): Promise<TranslationFile> {
  const file = lang === 'raw' ? 'messages.json' : `messages.${lang}.json`;

  let text = '';
  switch (mode) {
    case 'remote': {
      const resp = await fetch('https://' + firebase().projectId + '.imgix.net/' + i18nStoragePath + '/' + file, { cache: 'no-cache' });
      text = await resp.text();
      break;
    }
    case 'local': {
      const resp = await fetch('/assets/' + file);
      text = await resp.text();
      break;
    }
    case 'latest-build': {
      const resp = await fetch('https://' + firebase().projectId + '.imgix.net/' + i18nStoragePath + '/' + i18nLatestBuildPath + '/' + file, { cache: 'no-cache' });
      text = await resp.text();
      break;
    }
    default:
      throw new Error('Invalid mode');
  }

  const json: TranslationJson = JSON.parse(text);
  return { lang, json };
}

async function fetchTranslationFiles(mode: 'remote' | 'local' | 'latest-build' = 'remote'): Promise<TranslationFile[]> {
  const promises = translationFileTypes.map(lang => fetchTranslationFile(lang, mode));
  return Promise.all(promises);
}

export async function translationsToArray(): Promise<TranslationRow[]> {
  const data = await fetchTranslationFiles('remote');
  const latestBuild = await fetchTranslationFiles('latest-build');

  const raw = data.find(file => file.lang === 'raw');
  const rows: TranslationRow[] = [];
  for (const id of Object.keys(raw.json.translations)) {
    const entries: TranslationEntries = {};
    for (const { lang, json } of data) {
      entries[lang] = json.translations[id];
    }
    const build: TranslationEntries = {};
    for (const { lang, json } of latestBuild) {
      build[lang] = json.translations[id];
    }

    rows.push({ id, entries, build });
  }

  return rows;
}

export function translationArrayToJson(rows: TranslationRow[]) {
  const newTranslations: Partial<Record<SupportedLanguages, TranslationData>> = {};
  const langs = Object.keys(supportedLanguages) as SupportedLanguages[];
  for (const row of rows) {
    for (const lang of langs) {
      if (!newTranslations[lang]) newTranslations[lang] = {};
      newTranslations[lang][row.id] = row.entries[lang];
    }
  }

  return Object.entries(newTranslations).map(([lang, translations]: [SupportedLanguages, TranslationData]) => {
    const file: TranslationJson = { locale: fileTypeLocale[lang], translations };
    const translationFile: TranslationFile = { lang, json: file };
    return translationFile;
  });
}

export function jsonToUploadData(translationFile: TranslationFile, uid: string, prefix = 'messages',): UploadData {
  const fileName = `${prefix}.${translationFile.lang}.json`;
  return {
    file: new File([JSON.stringify(translationFile.json)], fileName, { type: 'application/json' }),
    fileName,
    metadata: {
      uid,
      collection: 'users', // @dev: not used for json files
      docId: i18nStoragePath,
      field: i18nStoragePath,
      privacy: 'public'
    }
  };
}
